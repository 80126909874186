import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

import help from '../../assets/help.svg';
import transfer from '../../assets/transfer.svg';
import ikamet from '../../assets/ikamet.svg';
import clean from '../../assets/clean.svg';
import bill from '../../assets/bill.svg';
import kdv from '../../assets/kdv.svg';
import doc from '../../assets/doc.svg';
import rent from '../../assets/rent.svg';
import school from '../../assets/school.svg';

const services = [
  { name: 'Помощь 24/7', icon: help },
  { name: 'Трансфер', icon: rent },
  { name: 'Икамет (ВНЖ)', icon: ikamet },
  { name: 'Клининг', icon: clean },
  { name: 'Коммуналка', icon: bill },
  { name: 'Налоги', icon: kdv },
  { name: 'Документы', icon: doc },
  { name: 'Аренда авто', icon: transfer },
  { name: 'Образование', icon: school },
];

const ServicePage = () => {
  return (
    <Box>
      <Box component="section" sx={{ p: 2 }}>
        <Typography component="div" variant="h5" sx={{ fontSize: '1.2rem', pl: 1, mb: 1, color: '#3c3c3c', fontWeight: '600' }}>
          Наши услуги
        </Typography>
       <Box
  sx={{
    display: 'flex',
    overflowX: 'auto', // Позволяет прокручивать плитки горизонтально
    gap: 2, // Расстояние между плитками
    pl: 1, // Внутренний отступ слева
    pr: 1, // Внутренний отступ справа
  }}
>
  {services.map((service, index) => (
    <Box
      key={index}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minWidth: '120px', // Фиксированная минимальная ширина для плиток
        p: 2,
        borderRadius: '15px',
        border: '2px solid #ffdfc8',
        backgroundColor: '#fff8f3',
        transition: 'all 0.3s ease',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          backgroundColor: '#ffe6d5',
        },
      }}
    >
      <img src={service.icon} alt={`${service.name} icon`} style={{ width: '40px', height: '40px', marginBottom: '8px' }} />
      <Typography variant="body1" sx={{ fontWeight: '500', color: '#3c3c3c' }}>
        {service.name}
      </Typography>
    </Box>
  ))}
</Box>

      </Box>
    </Box>
  );
};

export default ServicePage;


