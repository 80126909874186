import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import logo from '../../assets/logo.svg';
import menu from '../../assets/menu.svg';

const Header = () => {
    // Изменено: отдельные вызовы useSelector
    const userId = useSelector(state => state.userId);
    const userName = useSelector(state => state.userName);

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 2 }}>
                <Avatar
                    src={`https://rpclub.z-code.info/RPClub/files/${userId}.jpg`}
                    alt="Profile Picture"
                    sx={{ width: 100, height: 100 }}
                />
                <Typography variant="h6" sx={{ mt: 1 }}>{userName}</Typography>
                <Typography variant="body2" color="textSecondary">{userId}</Typography>
            </Box>
            <Divider />
        </Box>
    );

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pr: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, justifyContent: 'center' }}>
                    <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
                </Box>
                <IconButton onClick={toggleDrawer(true)}>
                    <Avatar
                        src={`https://rpclub.z-code.info/RPClub/files/${userId}.jpg`}
                        alt="Open Drawer"
                        sx={{ width: 40, height: 40, border: '1px solid #ec6608', mr: 1 }}
                    />
                    <img src={menu} alt="Logo" style={{ width: '25px', height: 'auto' }} />
                </IconButton>
            </Box>
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {DrawerList}
            </SwipeableDrawer>
        </div>
    );
};

export default Header;
