import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Menu from "./Components/Menu/Menu";
import HomePage from './Pages/HomePage/HomePage';
import ServicePage from './Pages/ServicePage/ServicePage';
import PromoPage from './Pages/PromoPage/PromoPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import Header from './Components/Header/Header';
import './App.css';
import StartImg from './assets/start.jpg';

function App() {
  const dispatch = useDispatch();
  const [telegramLoaded, setTelegramLoaded] = useState(false);

  useEffect(() => {
    // Проверяем, что объект Telegram существует
    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;

      tg.expand();
      const userId = tg.initDataUnsafe.user?.id || null;
      const userName = tg.initDataUnsafe.user?.username || null;

      // Отправляем данные пользователя в Redux
      dispatch({ type: 'SET_USER_DATA', payload: { userId, userName } });

      setTimeout(() => {
        setTelegramLoaded(true);
      }, 3000);
      tg.ready();
    }
  }, [dispatch]);

  if (!telegramLoaded) {
    return (
      <div className="loading-screen">
        <img src={StartImg} alt="Loading..." />
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/promotions" element={<PromoPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Menu />
      </div>
    </Router>
  );
}

export default App;
